<template>
	<ul class='a-select-search__dropdown--list'>
		<li>
			<div class='a-select-search__dropdown--item'
				:class='{"active": value ? option.code === value.code && option.airports.length === value.airports.length : null}'
				@click.stop="$emit('click', option), closeSelect()"
			>
				<div class='a-select-search__dropdown--icon'>
					<v-icon :icon='!option.airports[0] ? "air" : "house"' size='16' />
				</div>
				<div class='a-select-search__dropdown--content'>
					<p class='a-select-search__dropdown--title'>{{ option.name.split(',')[0] }}</p>
					<p class='a-select-search__dropdown--sub-title'>{{ option.name.split(',')[1] }}</p>
				</div>
				<div class='a-select-search__dropdown--action ml-auto'>
					<p class="a-select-search__dropdown--code">{{option.code}}</p>
				</div>
			</div>
			<slot />
		</li>
	</ul>
</template>

<script>
	export default {
		props: ["option", "value", "closeSelect"]
	}
</script>
