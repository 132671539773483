<template>
	<a-autocomplete
		:placeholder='placeholder'
		:value='value'
		@input='$emit("input", $event)'
		:requestName='getAutocompleteTerminals'
		:prepend-icon='prependIcon'
		item-value='code'
		:errors='errors'
		return-object
	>
		<template v-slot:dropdown="{ items, update, closeSelect, value }">
			<select-terminals-list
				v-for="(option, i) of items" :key='i'
				:option='option'
				:value='value'
				@click="update(option)"
				:closeSelect='closeSelect'
			>
				<template v-if='option.airports[0]'>
					<select-terminals-list
						class='child'
						v-for="(child, i) of option.airports" :key='i'
						:option='child'
						:value='value'
						@click="update(child)"
						:closeSelect='closeSelect'
					/>
				</template>
			</select-terminals-list>
		</template>
	</a-autocomplete>
</template>

<script>
	import SelectTerminalsList from "@/views/account/home/components/SelectTerminalsList";
	import { getAutocompleteTerminals } from '@/services/request';

	export default {
		props: ["value", "placeholder", "prepend-icon", "errors"],
		components: {
			SelectTerminalsList
		},
		methods: {
			getAutocompleteTerminals
		}
	}
</script>
